import { Stack, Typography } from '@mui/material';
import { isEmpty, isFinite, sumBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { IEvent, TicketSetting } from 'src/types';

interface EventStatusProps {
  event: IEvent;
  eventDate?: Date;
}

const ALMOST_SOLD_OUT = 10;

function getTotalTicketLeftPercentage(ticketSettings: TicketSetting[]): number {
  const totalQuantity = sumBy(ticketSettings, 'quantity');
  const totalQuantityLeft = sumBy(ticketSettings, 'quantityLeft');

  if (totalQuantity === 0) {
    return 0;
  }

  const percentageLeft = (totalQuantityLeft / totalQuantity) * 100;
  return isFinite(percentageLeft) ? percentageLeft : 0;
}

const EventStatus: React.FC<EventStatusProps> = ({ event, eventDate }) => {
  const currentDate = new Date();
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  if (!isMounted) {
    return null; // Or some kind of loading indicator
  }

  const isAlmostSoldOut =
    getTotalTicketLeftPercentage(event.ticketSettings) < ALMOST_SOLD_OUT &&
    !isEmpty(event.ticketSettings);

  return (
    <>
      {isAlmostSoldOut && !event?.isSoldOut && (
        <StatusStack text={<Trans i18nKey="event.event-status.almost-sold-out" />} />
      )}
      {event?.isSellingFast && !isAlmostSoldOut && !event?.isSoldOut && (
        <StatusStack text={<Trans i18nKey="event.event-status.selling-fast" />} />
      )}
      {event?.isSoldOut && <StatusStack text={<Trans i18nKey="event.event-status.sold-out" />} />}
      {eventDate && eventDate < currentDate && (
        <StatusStack text={<Trans i18nKey="event.event-status.sales-ended" />} />
      )}
      {EventType.EXCLUSIVE === event.type && (
        <StatusStack text={<Trans i18nKey="event.event-status.exclusive" />} />
      )}
    </>
  );
};

interface StatusStackProps {
  text: React.ReactNode;
  customStyle?: React.CSSProperties;
}

const StatusStack: React.FC<StatusStackProps> = ({ text, customStyle }) => (
  <Stack
    direction="row"
    alignItems="center"
    spacing={1}
    sx={{
      paddingX: 0.9,
      paddingY: 0.7,
      zIndex: 9,
      borderRadius: 1,
      top: 16,
      backgroundColor: '#212121',
      border: '1px solid white',
      ...customStyle,
    }}
  >
    <Typography variant="caption">{text}</Typography>
  </Stack>
);

export default EventStatus;

enum EventType {
  EXCLUSIVE = 'EXCLUSIVE',
  PUBLIC = 'PUBLIC',
}
